import { BotContact, BotContactFilters } from '@interfaces/reports/botContacts';
import {
  CartAbandonmentReport,
  CartAbandonmentReportFilters,
} from '@interfaces/reports/cartAbandonments';
import {
  BlockTelegramUserDto,
  ReportCSVResponse,
  ReportResponse,
} from '@interfaces/reports';
import { FindReportDto } from '@interfaces/reports';
import { fetcher } from '@utils/fetcher';
import { NewUsers } from '@interfaces/reports/newUsers';
import { Sale, SalesFilters } from '@interfaces/reports/sales';
import {
  ReceiptHistory,
  ReceiptHistoryFilters,
} from '@interfaces/reports/receiptHistory';
import {
  ReceiptForecast,
  ReceiptForecastsFilters,
} from '@interfaces/reports/receiptForecasts';
import { Refund, RefundsFilters } from '@interfaces/reports/refunds';
import {
  Subscription,
  SubscriptionFilters,
} from '@interfaces/reports/subscriptions';
import { SalesSummary } from '@interfaces/reports/salesSummary';
import { SalesHistory } from '@interfaces/reports/salesHistory';
import { ReferrerCommission } from '@interfaces/reports/referrerCommissions';
import {
  AffiliatedSale,
  AffiliatedSalesFilters,
} from '@interfaces/reports/affiliatedSales';
import {
  SaleAsAffiliate,
  SalesAsAffiliateFilters,
} from '@interfaces/reports/salesAsAffiliate';
import { DashboardFilters } from '@interfaces/dashboard';

export enum ApiReportUrls {
  BotContacts = '/api/reports/botContacts',
  CartAbandonments = '/api/reports/cartAbandonments',
  NewUsers = '/api/reports/newUsers',
  ReceiptForecasts = '/api/reports/receiptForecasts',
  ReceiptHistory = '/api/reports/receiptHistory',
  Refunds = '/api/reports/refunds',
  Sales = '/api/reports/sales',
  SalesHistory = '/api/reports/salesHistory',
  SalesSummary = '/api/reports/salesSummary',
  Subscriptions = '/api/reports/subscriptions',
  ReferrerCommissions = '/api/reports/referrerCommissions',
  AffiliatedSales = '/api/reports/affiliatedSales',
  SalesAsAffiliate = '/api/reports/salesAsAffiliate',
  BlockTelegramUser = '/api/reports/blockTelegramUser',
  UnblockTelegramUser = '/api/reports/unblockTelegramUser',
}

export const findBotContacts = async (
  dto: FindReportDto<BotContactFilters>,
): Promise<ReportResponse<BotContact[]>> =>
  fetcher(ApiReportUrls.BotContacts, {
    method: 'POST',
    body: dto,
  });

export const findCartAbandonments = async (
  dto: FindReportDto<CartAbandonmentReportFilters>,
): Promise<ReportResponse<CartAbandonmentReport[]>> =>
  fetcher(ApiReportUrls.CartAbandonments, {
    method: 'POST',
    body: dto,
  });

export const findSales = async (
  dto: FindReportDto<SalesFilters>,
): Promise<ReportResponse<Sale[]>> =>
  fetcher(ApiReportUrls.Sales, {
    method: 'POST',
    body: dto,
  });

export const findReceiptHistory = async (
  dto: FindReportDto<ReceiptHistoryFilters>,
): Promise<ReportResponse<ReceiptHistory[]>> =>
  fetcher(ApiReportUrls.ReceiptHistory, {
    method: 'POST',
    body: dto,
  });

export const findReceiptForecasts = async (
  dto: FindReportDto<ReceiptForecastsFilters>,
): Promise<ReportResponse<ReceiptForecast[]>> =>
  fetcher(ApiReportUrls.ReceiptForecasts, {
    method: 'POST',
    body: dto,
  });

export const findRefunds = async (
  dto: FindReportDto<RefundsFilters>,
): Promise<ReportResponse<Refund[]>> =>
  fetcher(ApiReportUrls.Refunds, {
    method: 'POST',
    body: dto,
  });

export const findSubscriptions = async (
  dto: FindReportDto<SubscriptionFilters>,
): Promise<ReportResponse<Subscription[]>> =>
  fetcher(ApiReportUrls.Subscriptions, {
    method: 'POST',
    body: dto,
  });

export const findNewUsers = async (
  filters: Partial<DashboardFilters>,
): Promise<ReportResponse<NewUsers>> =>
  fetcher(ApiReportUrls.NewUsers, {
    method: 'POST',
    body: filters,
  });

export const findSalesSummary = async (
  filters: Partial<DashboardFilters>,
): Promise<ReportResponse<SalesSummary>> =>
  fetcher(ApiReportUrls.SalesSummary, {
    method: 'POST',
    body: filters,
  });

export const findSalesHistory = async (
  filters: Partial<DashboardFilters>,
): Promise<ReportResponse<SalesHistory[]>> =>
  fetcher(ApiReportUrls.SalesHistory, {
    method: 'POST',
    body: filters,
  });

export const findReferrerCommissions = async (
  filters: FindReportDto,
): Promise<ReportResponse<ReferrerCommission[]>> =>
  fetcher(ApiReportUrls.ReferrerCommissions, {
    method: 'POST',
    body: filters,
  });

export const findAffiliatedSales = async (
  filters: FindReportDto<AffiliatedSalesFilters>,
): Promise<ReportResponse<AffiliatedSale[]>> =>
  fetcher(ApiReportUrls.AffiliatedSales, {
    method: 'POST',
    body: filters,
  });

export const findSalesAsAffiliate = async (
  filters: FindReportDto<SalesAsAffiliateFilters>,
): Promise<ReportResponse<SaleAsAffiliate[]>> =>
  fetcher(ApiReportUrls.SalesAsAffiliate, {
    method: 'POST',
    body: filters,
  });

export const exportVibReport = async (
  reportUrl: ApiReportUrls,
  dto: FindReportDto,
): Promise<ReportResponse<ReportCSVResponse>> =>
  fetcher(reportUrl, {
    method: 'POST',
    body: dto,
  });

export const blockTelegramUser = async (dto: BlockTelegramUserDto) => {
  fetcher(ApiReportUrls.BlockTelegramUser, {
    method: 'POST',
    body: dto,
  });
};

export const unblockTelegramUser = async (
  botConfigId: number,
  telegramUserId: number,
) => {
  fetcher(
    `${ApiReportUrls.UnblockTelegramUser}/${botConfigId}/${telegramUserId}`,
    {
      method: 'DELETE',
      body: {},
    },
  );
};
